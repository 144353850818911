<template>
  <div>
    <div clas="item">
      <toggleSwitch
        :title="$t('settings.verify.title')"
        :help="$t('settings.verify.info')"
        :value="data"
        :callback="setValue"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      data: this.getData()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getData() {
      if (this.$store.state.business.unsaved.bookingMethod === "default") {
        return true;
      } else {
        return false;
      }
    },
    setValue() {
      if (this.data === true) {
        this.data = false;
      } else {
        this.data = true;
      }
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (this.data) {
        data.bookingMethod = "default";
      } else {
        data.bookingMethod = "noVerify";
      }
      this.$store.commit("business/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
